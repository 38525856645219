<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card>
          <template v-slot:headerTitle>
            <h4 class="card-title"><b>LIQUIDACION DE NOMINA</b></h4>
          </template>
          <div class="text-right px-4 mt-3">
            <!--   -->
            <!-- <b-button v-b-modal.my-modal @click="infoForPerson(item)">Ver más</b-button> -->
            <button @click="$router.push('/helex/liquidacion_agregar')" class="mx-1 btn btn-success"><i class="fas fa-plus"></i>Nuevo período de liquidación</button>
          </div>
          <div class="table-responsive px-4 mt-3">
            <table id="datatable_nomina" class="table table-bordered table-responsive-md table-striped text-center">
              <thead>
                <tr>
                  <th>Período de nómina</th>
                  <th>Tipo liquidación</th>
                  <th>Salarios</th>
                  <th>Pagos adicionales</th>
                  <th>Deducciones</th>
                  <th>Total a pagar</th>
                  <!-- <th>Total costo</th> -->
                  <th>Opciones</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in listaItems" :key="item.id">
                  <td>
                    {{ $moment.tz(item.fechaInicio, "America/Costa_Rica").format("DD-MM-YYYY") + " / " + $moment.tz(item.fechaFin, "America/Costa_Rica").format("DD-MM-YYYY") }}
                  </td>
                  <td>
                    {{
                      item.tipoLiquidacion != null
                        ? item.tipoLiquidacion == 1 || item.tipoLiquidacion == 2
                          ? "Prima Servicios"
                          : item.tipoLiquidacion == "3"
                          ? "Nomina"
                          : item.tipoLiquidacion == "4"
                          ? "Cesantías fin de año"
                          : "--"
                        : "---"
                    }}
                  </td>
                  <td>{{ item.totalSalarios != null ? item.totalSalarios.toLocaleString("es-CO", { style: "currency", currency: "COP" }) : "$ 0.00" }}</td>
                  <td>
                    {{ item.totalPagosAdicionales != null ? item.totalPagosAdicionales.toLocaleString("es-CO", { style: "currency", currency: "COP" }) : "$ 0.00" }}
                  </td>
                  <td>{{ item.totalDeducciones != null ? item.totalDeducciones.toLocaleString("es-CO", { style: "currency", currency: "COP" }) : "$ 0.00" }}</td>
                  <td>{{ item.totalAPagar != null ? item.totalAPagar.toLocaleString("es-CO", { style: "currency", currency: "COP" }) : "$ 0.00" }}</td>
                  <!-- <td>{{ item.totalCostos != null ? item.totalCostos.toLocaleString("es-CO", { style: "currency", currency: "COP" }) : "$ 0.00" }}</td> -->
                  <td>
                    <span class="pointer"
                      ><i
                        @click="
                          item.tipoLiquidacion == 1 || item.tipoLiquidacion == 2 || item.tipoLiquidacion == 4 || item.tipoLiquidacion == 5 || item.tipoLiquidacion == 6
                            ? $router.push('/helex/liquidacion_editarv2/' + item.id)
                            : $router.push('/helex/liquidacion_editar/' + item.id)
                        "
                        class="fas fa-solid fa-pen px-1 text-success"
                      ></i>
                      <i @click="deleteRow(item.id)" class="fas fa-solid fa-trash px-1 text-danger"></i>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from "../../../../config/pluginInit";
const Swal = require("sweetalert2");

export default {
  name: "DataTable",
  data() {
    return {
      listaItems: [{}],
      logoEmpresa: "",
      usuario: {},
    };
  },
  async mounted() {
    try {
      this.$isLoading(true);
      this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
      await this.getData();
      core.index();
      window.$("#datatable_departamentos").DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
        },
      });
      this.$isLoading(false);
    } catch (error) {
      this.$isLoading(false);
      console.log("error", error);
    }
  },
  methods: {
    async deleteRow(id) {
      Swal.fire({
        title: "Seguro eliminar registro?",
        text: "Después de eliminarlo no podrás recuperarlo!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si!",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          //let loader = this.$loading.show();
          this.$isLoading(true);
          await this.$store.dispatch("hl_delete", {
            path: "NominaLiquidacion/DelNominaLiquidacion/" + id,
          });

          //console.log("res", res);
          this.$isLoading(false);

          await this.getData();

          Swal.fire("Listo!", "Registro eliminado correctamente.", "success");
        }
      });
    },
    async getData() {
      try {
        console.log("LISTA NOMINAAAAAAAAAAAA.....................");
        let res = await this.$store.dispatch("hl_get", {
          path: "NominaLiquidacion/GetNominaLiquidacion/" + this.usuario.empresa.id,
        });
        console.log("GetNominaLiquidacion... ", res);
        if (res.length > 0) {
          this.listaItems = res;
          // this.listaItems = [];
        } else {
          this.listaItems = [];
        }
      } catch (error) {
        this.listaItems = [];
        console.log("err", error);
      }
    },
  },
};
</script>
